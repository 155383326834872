// NOT USED
import * as React from 'react';
import type { HeadFC } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby';
import Seo from '../components/Seo';
import Layout from '../components/Layout';
import Container from '../components/Container';
import Button from '../components/Button';
import Section from '../components/Section';
import { ArrowLeftIcon } from '@heroicons/react/24/solid';
import HeroInnerPage from '../components/HeroInnerPage';
const CareersPage = ({ data }: any) => {
  const { t } = useTranslation();
  const { markdownRemark } = data;

  if (!markdownRemark) {
    return (
      <Layout>
        <div className="my-auto text-center">
          <Container>
            <div className="prose">
              <h1>{t('common.message.onlyinrench')}</h1>
              <p>
                <Button to="/use-cases" language="fr">
                  {t('common.button.back')}
                </Button>
              </p>
            </div>
          </Container>
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <HeroInnerPage title={t('page.use_cases.title')} />
      <Section>
        <Container>
          <div className="prose mx-auto max-w-screen-md">
            <h1>{markdownRemark?.frontmatter?.title}</h1>
            <div dangerouslySetInnerHTML={{ __html: markdownRemark?.html }} />
            <div className="not-prose">
              <p className="mt-12">
                <Button variant="ghost" to="/use-cases">
                  <ArrowLeftIcon className="h-6 w-6" />
                  <span>{t('button.back')}</span>
                </Button>
              </p>
            </div>
          </div>
        </Container>
      </Section>
    </Layout>
  );
};

export default CareersPage;

export const Head: HeadFC = ({ location, data }: any) => {
  const { markdownRemark } = data;

  return <Seo location={location} title={`${markdownRemark?.frontmatter?.title} | Austra`} />;
};

export const query = graphql`
  query ($language: String!, $slug: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }, frontmatter: { locale: { eq: $language } }) {
      frontmatter {
        title
        locale
      }
      html
    }
  }
`;
